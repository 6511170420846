export const EnvironmentUrl = {
  test: "https://test.auvcdn.xyz/",
  prod: "https://test.auvcdn.xyz",
  imgTest: "https://img.banana8.xyz/",
  imgProd: "https://img.banana8.xyz/",
};

export const getEnvironment = () => {
  const curUrl = location.origin;
  let res = "prod"; // 默认为生产
  if (
    curUrl.indexOf("http://localhost:") >= 0 ||
    curUrl.indexOf("https://localhost:") >= 0
  ) {
    res = "dev";
  } else if (
    curUrl.indexOf("https://test.") >= 0 ||
    curUrl.indexOf("http://test.") >= 0
  ) {
    res = "test";
  }
  return res;
};
